import * as React from 'react'
import Lottie, { LottieRefCurrentProps } from 'lottie-react'

// Hooks
import { useTheme } from 'styled-components/macro'
import useIsMobile from '@hooks/useIsMobile'
import { useInView } from 'react-intersection-observer'
// Animations
import LemonAnimation from '@components/AnimatedLemonDudeComponent'

// Components
import SectionComponent from '@components/SectionComponent'
import { FadeIn } from '@components/Animations'
import SquaredLinkComponent from '@components/SquaredLinkComponent'
import { Heading, Highlight, Paragraph, Caption } from '@components/Typography'
import Grid from '@components/ViewGridComponent'
import Spacer from '@components/SpacerComponent'

const WeDoOurBit: React.FC = () => {
  const theme = useTheme()
  const isMobile = useIsMobile()
  const { ref, inView } = useInView({ threshold: 0.4 })

  return (
    <SectionComponent id='why' ref={ref}>
      <Spacer bottom='xl' />
      <Grid.Item
        column={isMobile ? '2 / 12' : '2 / 5'}
        order={1}
        alignSelfStart
        css={`
          padding-bottom: ${isMobile ? theme.spacing.xl : 'initial'};
        `}
      >
        <LemonAnimation shouldAnimate={inView} />
      </Grid.Item>
      <Grid.Item column={isMobile ? '1 / 13' : '6 / 13'} order={2}>
        <FadeIn>
          <Caption>Our Vision</Caption>
          <Spacer bottom='sm' />
          <Heading
            css={`
              padding-bottom: ${theme.spacing.sm};
            `}
          >
            To do our bit to solve the planet's challenges, through
            <Highlight> tech with purpose.</Highlight>
          </Heading>
          <Spacer bottom={isMobile ? 'sm' : 'lg'}>
            <Paragraph size='md'>
              App development and web design projects that deliver social
              impact, delivered with the Big Lemon secret sauce: transparency
              and care. Tech shouldn't be a barrier to achieving big change.
            </Paragraph>
          </Spacer>
          <SquaredLinkComponent label='our work' to='/our-work' />
        </FadeIn>
      </Grid.Item>
    </SectionComponent>
  )
}

export default WeDoOurBit
