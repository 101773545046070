import {
  motion,
  useReducedMotion,
  useTransform,
  useViewportScroll,
} from 'framer-motion'
import React from 'react'

type AnimatedImageProps = {
  src: string
  offset: number | 'full' | 'half'
  className?: string
  scale?: boolean
}

const AnimatedImage = (props: AnimatedImageProps) => {
  const { scrollY } = useViewportScroll()
  const shouldReduceMotion = useReducedMotion()
  let height = 1000
  let offset = props.offset

  const isBrowser = typeof window !== 'undefined'

  if (isBrowser) {
    height = window.innerHeight
    if (props.offset === 'full') {
      offset = window.innerHeight
    }

    if (props.offset === 'half') {
      offset = window.innerHeight / 2
    }
  }

  if (shouldReduceMotion) {
    offset = 0
  }

  const y1 = useTransform(scrollY, [0, height], [0, offset])
  const scale1 = props.scale
    ? useTransform(scrollY, [height, height + 250], [1, 2])
    : 1

  return (
    <motion.img
      src={props.src}
      style={{
        y: y1,

        scale: scale1,
      }}
      className={props.className}
      alt=''
    />
  )
}

export default AnimatedImage
