import * as React from 'react'

const Camden_Council = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='var(--color-certifications-logo)'
    viewBox='0 0 500 103.57'
  >
    <g id='Layer_2' data-name='Layer 2'>
      <g id='layer'>
        <path d='M345.28,65.52V53c0-10.14-5.45-15.25-16.18-15.25-6.67,0-12.36,2.31-16.33,6.52-2.14-3.88-6.92-6.52-12.78-6.52a20.47,20.47,0,0,0-14.84,5.85V37.89a3.09,3.09,0,0,1-2.73,1H274c3.14,2.64,3.71,5.2,3.71,9.57V65c0,4.37-.49,6.84-3.79,9.48H290c-3.71-3.05-3.71-5.6-3.71-9.49v-8.5c0-7.75,4.71-12.12,11.55-12.12,6.6,0,9.24,3.88,9.24,11.71V65c0,4.29-.41,6.76-3.79,9.49h16.08c-3.63-3-3.8-5.52-3.8-9.49V56.53c0-7.67,4.7-12.13,11.8-12.13,6.76,0,9.4,3.88,9.4,11.72V65c0,4.38-.49,6.85-3.79,9.49h16C345.35,71.54,345.28,69.15,345.28,65.52Z' />
        <path d='M266.59,64.54V53.32c0-10.81-4.95-15.59-16.82-15.59-7,0-12.62,1.56-16.33,1.56a4.68,4.68,0,0,1-1.82-.33l-3.3,9.9c8.17-3.38,15.43-4.7,19.88-4.7,6.93,0,9.82,2.06,9.82,7.67v.25c-21.86.24-32.5,2.89-32.5,13,0,6.44,6.18,10.56,17.57,10.56h0c7.25,0,13-1.49,16.65-4.37.34,2.06,1.57,3.22,3,3.22h8.58C267.17,71.22,266.59,68.41,266.59,64.54Zm-8.49-4.21c0,5-5.12,9-13.94,9-6.35,0-9.82-2.23-9.82-5.36,0-4.62,7.76-5.53,23.76-5.78Z' />
        <path d='M200.12,31.62a31.85,31.85,0,0,1,16.17,4.29l3.62-10.06a7.84,7.84,0,0,1-2.39.33c-3.55,0-9.4-1.9-17-1.9-19,0-30.27,10.4-30.27,26.65,0,15.09,10.89,24.9,28.21,24.9,7.5,0,13.36-1.89,17.07-1.89a7,7,0,0,1,3.05.57V63.38c-7.09,3.3-13.69,4.87-19.71,4.87-11.38,0-18.14-6.85-18.14-18S188.24,31.62,200.12,31.62Z' />
        <path d='M484,74.51h16c-3.63-2.88-3.71-5.45-3.71-9.49V53c0-10.22-5.28-15.25-16.17-15.25-6.76,0-12.12,1.81-16.49,5.85V37.89a3.56,3.56,0,0,1-2.81,1h-8.41c3.22,2.73,3.71,5.28,3.71,9.57V65c0,4.21-.32,6.68-3.79,9.48h16.08c-3.62-2.88-3.8-5.44-3.8-9.49V55.87c0-7.76,5-12,13.21-12,7.5,0,9.9,3.87,9.9,11.79V65C487.72,69.23,487.47,71.7,484,74.51Z' />
        <path d='M396.66,65.11V29.48c0-1.82.17-2.89,1.32-3.8H384.37c3.22,3,3.79,5.28,3.79,9.65v8.09c-2.23-3.3-7.83-5.78-14.27-5.78-12.37,0-20.28,7.18-20.28,18.81,0,11.8,7.92,19.14,20,19.14,6.84,0,12.36-2.23,15.5-5.94v.25c0,3.13,1.32,4.61,4.2,4.61h6.93C396.74,71.46,396.66,69,396.66,65.11Zm-21.12,4.21c-8,0-12.94-4.63-12.94-12.7S367.13,44,374.8,44c8.17,0,13.12,4.78,13.12,12.78S383.13,69.32,375.54,69.32Z' />
        <path d='M426.6,37.73c-13.2,0-21.45,7.59-21.45,19.38,0,11.63,7.34,18.56,21.45,18.56,6.68,0,11.71-1.24,15.09-1.24h0a26.69,26.69,0,0,1,3.22.25V65a35.34,35.34,0,0,1-16.33,4c-8.75,0-14.27-4-14.27-8.91v-.24H444a5,5,0,0,1,3,.74c.08-1.16.16-2.31.16-3.47C447.13,44.41,440.12,37.73,426.6,37.73Zm11.46,15.92H414.31c.32-5.53,4.7-9.33,12.29-9.33,7.25,0,11.46,3.8,11.46,9.16Z' />
        <path d='M89,85.64V82.48h8.47s4.54-.65,2.49-5c-.11-.22-1-.33-1-.33H87L69.88,85.3v18.27h33.54A19.9,19.9,0,0,0,118,85.64H89Z' />
        <path d='M35.2,77.17s-.9.11-1,.33c-2.05,4.33,2.49,5,2.49,5h8.46v3.16h-29a19.88,19.88,0,0,0,14.61,17.93H64.25V85.3l-17.1-8.13Z' />
        <path d='M29.06,82s.89-.1,1-.32c2.06-4.33-2.49-5-2.49-5H19.1V73.53H48.15A19.88,19.88,0,0,0,33.54,55.6H0V73.86L17.1,82Z' />
        <path d='M98.94,26.4s.88-.12,1-.33c2-4.33-2.49-5-2.49-5H89V17.93H118A19.88,19.88,0,0,0,103.42,0H69.88V18.27L87,26.4Z' />
        <path d='M16.1,17.93H45.15v3.15H36.69s-4.54.66-2.49,5c.1.21,1,.33,1,.33H47.15l17.1-8.13V0H30.71A19.86,19.86,0,0,0,16.1,17.93Z' />
        <path d='M105.8,21.58s-.89.11-1,.32c-2,4.34,2.5,5,2.5,5h8.47V30H86.7A19.9,19.9,0,0,0,101.32,48h33.54V29.71l-17.11-8.13Z' />
        <path d='M48.15,30h-29V26.89h8.46s4.55-.65,2.49-5c-.1-.21-1-.32-1-.32h-12L0,29.71V48H33.54A19.9,19.9,0,0,0,48.15,30Z' />
        <path d='M86.7,73.53h29.07v3.16H107.3s-4.55.65-2.5,5c.11.22,1,.32,1,.32h12l17.11-8.13V55.6H101.32A19.89,19.89,0,0,0,86.7,73.53Z' />
      </g>
    </g>
  </svg>
)

export default Camden_Council
