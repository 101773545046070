import * as React from 'react'

const Glasgow_Caledonian_University = () => (
  <svg
    fill='var(--color-certifications-logo)'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 500 281.91'
  >
    <g id='Layer_2' data-name='Layer 2'>
      <g id='layer'>
        <path d='M80.92,247.25A125.35,125.35,0,0,1,138.67,10.83h.75a125,125,0,0,1,84.25,33.34,1,1,0,0,0,.67.25,1,1,0,0,0,.83-.42,1.16,1.16,0,0,0-.17-1.5A129.58,129.58,0,0,0,129.92,0h-.66A129.49,129.49,0,0,0,80,249.16a.68.68,0,0,0,.41.09,1,1,0,0,0,.92-.59A1,1,0,0,0,80.92,247.25Z' />
        <path d='M214.25,148.75v-42.5c0-2.67.75-4.83,3.67-5.75a34.39,34.39,0,0,1,4-1l1.5-.25a2.68,2.68,0,0,0,1.92-2.5V94.83h-23.5a7.65,7.65,0,0,0-7.67,7.67h0v47.41c0,18.34,13,29.17,35.75,29.17,9.83,0,17.67-2.58,23.5-7.92s8.92-12.41,8.92-21.08l-.09-43.75c0-2.66.75-4.83,3.67-5.75a24.29,24.29,0,0,1,4.83-1.16l.59-.09a2.67,2.67,0,0,0,1.91-2.5V94.92h-10a7.64,7.64,0,0,0-7.66,7.66V148c0,15.33-7.84,23.5-20.84,23.5C221.42,171.5,214.25,164.58,214.25,148.75Z' />
        <path d='M191.42,156.58l-1.5-.75a3.87,3.87,0,0,0-5.33,1.08c-6.34,9.42-13.84,14.09-23.34,14.09-7.25,0-13.25-2.42-17.5-8.34-4.16-5.41-6.91-14.41-6.91-27,0-22.5,7.5-35.5,21.66-35.5,10.92,0,18.25,7.25,23.75,23.41l.25.5a3.68,3.68,0,0,0,3.17,1.84H188l.09-23.84a7.65,7.65,0,0,0-7.67-7.41H157.84c-12.33,0-23.5,4.41-31,11.91-7.83,7.75-12.33,18.59-12.33,30.92s4.5,23.16,12,30.16c7.5,7.25,18.08,11.17,29.74,11.17,9.67,0,16.59-2.5,22.5-6.67C184.17,168.5,188.25,163.16,191.42,156.58Z' />
        <path d='M105.92,126.08h2.25V102.5a7.64,7.64,0,0,0-7.66-7.67H77.09c-13.33,0-24.58,4.17-32.25,11.92-7.83,7.5-12,18.42-12,30.92,0,13.33,4.58,23.83,12.33,30.74,7.5,7,18,10.59,29.5,10.59A77.54,77.54,0,0,0,95,176a79.59,79.59,0,0,0,14.16-5.09l.59-.25a3.93,3.93,0,0,0,1.91-3.16V147.67a7.63,7.63,0,0,0-4.58-7.09,8.88,8.88,0,0,0-2-.58,4.91,4.91,0,0,0-1.08-.08H80.26v1.25A3.78,3.78,0,0,0,83.92,145a22.74,22.74,0,0,1,4.84,1.42c2.91,1.41,3.58,3.08,3.58,5.74v16.25c-2.75,2.67-8.17,4.92-14.75,4.92-14.17,0-22.17-14.08-22.17-37.58,0-22.17,8.17-35.5,22.59-35.5,11.5,0,19.08,7.42,24.5,23.42v.08A3.8,3.8,0,0,0,105.92,126.08Z' />
        <path d='M121.42,215.33a11.93,11.93,0,0,1,2.92-.67l.17-1.58H110.26v1.67a14,14,0,0,1,4,.75c1.08.41,1.33,1.5,1.33,2.75V220a27.1,27.1,0,0,1-.17,4.5,9.92,9.92,0,0,1-6.08,1.91A9.64,9.64,0,0,1,101,222a18.23,18.23,0,0,1-2.92-10.59c0-4.66,1.08-8.58,2.92-11.08a8.82,8.82,0,0,1,7.66-4,7.91,7.91,0,0,1,6.59,3.17,17.64,17.64,0,0,1,3,6.91h1.83l-.17-11.33h-1.83l-.92,1.58a16.63,16.63,0,0,0-20.08,2.59,18,18,0,0,0-4.67,12.41,17.07,17.07,0,0,0,4.75,12.25,15.77,15.77,0,0,0,11.09,4.5,29.79,29.79,0,0,0,12-2.83,10.29,10.29,0,0,0-.09-1.58v-6.25A2.42,2.42,0,0,1,121.42,215.33Z' />
        <path d='M127.59,225.91a14.8,14.8,0,0,1-2.08.25v1.5h11l-.09-1.5a4.7,4.7,0,0,1-2.08-.5,2,2,0,0,1-1.25-2.08V194.83l-.33-.33-8.5.5v1.5a11.19,11.19,0,0,1,3.33.75,3.38,3.38,0,0,1,1.25,2.66v23.92C128.84,225,128.42,225.66,127.59,225.91Z' />
        <path d='M335,223.58V194.83l-.33-.33-8.5.5v1.5a11.25,11.25,0,0,1,3.25.75,3.27,3.27,0,0,1,1.25,2.66v23.92c0,1.17-.42,1.83-1.25,2.08a14.8,14.8,0,0,1-2.08.25v1.5h11v-1.5a4.71,4.71,0,0,1-2.09-.5A2,2,0,0,1,335,223.58Z' />
        <path d='M152.25,224.91c.42,2.09,1.84,3.25,4.25,3.25a9.54,9.54,0,0,0,3.67-.91l-.08-1.34a6.73,6.73,0,0,1-2.59-.25,2.36,2.36,0,0,1-1.25-2.33c0-4,.17-7,.17-11.33,0-3.09-.83-4.92-2.42-6.09s-3.41-1.41-6-1.41a11,11,0,0,0-5.33,1.58c-1.67.92-2.75,2.25-2.75,3.67s.67,2.66,2.25,2.66c1.75,0,2.75-1.08,2.75-2.08s-.42-2.42-.42-3a5.11,5.11,0,0,1,3.25-1.08,3.92,3.92,0,0,1,3.67,2.08,7.87,7.87,0,0,1,.67,2.75v2.25a27,27,0,0,1-4.25,1.67c-2.09.75-3.5,1.16-4.17,1.41a9.06,9.06,0,0,0-3.25,2.09,6,6,0,0,0-1.5,4.16,5.47,5.47,0,0,0,5.83,5.67,9.34,9.34,0,0,0,7.34-3.42Zm-5.25.75c-2,0-3.5-1.16-3.5-3.66a5.2,5.2,0,0,1,2.5-4.34,24.6,24.6,0,0,1,6.17-2.5l-.25,7.84A6,6,0,0,1,147,225.66Z' />
        <path d='M170.75,226.58a6.67,6.67,0,0,1-4.91-2.42,10.28,10.28,0,0,1-2.25-4.25H162l.25,7.84h1.67l.5-1.42a11,11,0,0,0,6,2,9.07,9.07,0,0,0,6.08-2,6.28,6.28,0,0,0,2.42-5.17,5.88,5.88,0,0,0-2.17-4.58,14.45,14.45,0,0,0-5.08-2.5,19.74,19.74,0,0,1-4.33-1.75,3.11,3.11,0,0,1-1.42-2.83c0-1.75,1.5-3.25,4-3.25,3.08,0,5.42,2.25,6,5.5h1.58l-.33-7.25H175.5l-.5,1.25a10.93,10.93,0,0,0-4.91-1.17,7.8,7.8,0,0,0-5.5,1.92,6.11,6.11,0,0,0,.25,9.41,14.07,14.07,0,0,0,4.66,2.17,13.36,13.36,0,0,1,4.34,1.83,3.4,3.4,0,0,1,1.5,2.84C175.34,225.25,173.67,226.58,170.75,226.58Z' />
        <path d='M181.09,231.91c0,3.92,3.33,5.92,10.16,5.92,4.25,0,6.92-.92,9-2.33a6.61,6.61,0,0,0,2.84-5.5,5.54,5.54,0,0,0-1.92-4.5,6.21,6.21,0,0,0-4.08-1.75c-.5-.09-1.34-.09-2.5-.17s-2.09-.17-2.75-.17a15.33,15.33,0,0,1-3.42-.33,1.76,1.76,0,0,1-1.67-1.83,2.23,2.23,0,0,1,1.67-2h2.75a8.8,8.8,0,0,0,5.67-2.09,6.31,6.31,0,0,0,2.41-5,7.07,7.07,0,0,0-1.66-4.58v-.17h5.33v-2.16h-7.83a17.22,17.22,0,0,0-4.17-.75,9.09,9.09,0,0,0-6.08,2.08,6.53,6.53,0,0,0-2.42,5.25c0,2.83,1.75,5,4.58,6.33v.34c-2.33.91-3.41,2.33-3.41,4a3.53,3.53,0,0,0,1.33,2.75,11,11,0,0,0,2.67,1.25v.25C183.84,227,181.09,228.83,181.09,231.91Zm5.83-20.16a8.5,8.5,0,0,1,.83-4,3.31,3.31,0,0,1,3.09-1.67c2.58,0,4.08,2.25,4.16,5.83,0,3.17-1.41,5.59-4.08,5.59C188.5,217.5,186.92,215.16,186.92,211.75Zm2.75,15.58a70.35,70.35,0,0,1,7.25.33,2.94,2.94,0,0,1,2.42,3.17c0,3.58-2.75,5.25-7.25,5.25-2.59,0-4.34-.5-5.42-1.33a3.5,3.5,0,0,1-1.58-2.92C185.09,229.41,186.34,227.91,189.67,227.33Z' />
        <path d='M223.5,207.58a11.19,11.19,0,0,0-15.83.17,12.27,12.27,0,0,0-3.08,8.66,11.7,11.7,0,0,0,3.25,8.75,10.8,10.8,0,0,0,7.58,3.17,10.31,10.31,0,0,0,8.33-3.58,12.56,12.56,0,0,0,2.84-8.67C226.59,212.5,225.59,209.66,223.5,207.58Zm-7.66,19.08a5.26,5.26,0,0,1-4.92-3.16,15.56,15.56,0,0,1-1.5-7.42,14.15,14.15,0,0,1,1.33-6.67,5.27,5.27,0,0,1,5-3.25,5.35,5.35,0,0,1,4.84,3.17,17.89,17.89,0,0,1,1.41,6.83C222,222.75,219.67,226.66,215.84,226.66Z' />
        <path d='M230.34,209c.83,2.5,1.83,5.41,2.91,8.75s2.25,6.58,3.34,10h1.91l6.17-16.59,6.08,16.59h2a51.65,51.65,0,0,1,1.59-5.17c1.66-5,2.83-8.67,4.41-13.17a4.59,4.59,0,0,1,3.67-2.91h-.33v-1.34H253.5v1.42a4.32,4.32,0,0,1,1.75.33c.75.25,1.25.75,1.25,1.25a8.33,8.33,0,0,1-.41,2.09c-.92,3.33-2.34,7.25-3.67,11.33l-5.83-16.08h-2.17l-5.75,16.25c-1.92-6.34-3.08-9.92-4-13.09a4.44,4.44,0,0,1-.25-1c0-.75,1.5-1,2.5-1v-1.58h-10v1.5A4.06,4.06,0,0,1,230.34,209Z' />
        <path d='M296.5,223.58a1.32,1.32,0,0,1-.41.33,9.85,9.85,0,0,1-13.67-2.33c-2.08-2.67-3.08-6.17-3.08-10.33,0-4.75,1.08-8.67,2.83-11.09a9.18,9.18,0,0,1,7.5-3.91,7.16,7.16,0,0,1,6.08,3.33,20,20,0,0,1,2.84,7.08h1.91l-.25-11.58h-1.83l-.92,1.58a15.3,15.3,0,0,0-19.08,2.5,17.91,17.91,0,0,0-4.5,12.5,17.63,17.63,0,0,0,4.42,12.25,14.13,14.13,0,0,0,10.33,4.42c6,0,10.33-2.58,12.92-9l-1.67-.83A13.17,13.17,0,0,1,296.5,223.58Z' />
        <path d='M322.09,225.66a2.33,2.33,0,0,1-1.25-2.33c0-4,.16-7,.16-11.33,0-3.09-.83-4.92-2.41-6.09s-3.42-1.41-6-1.41a10.78,10.78,0,0,0-5.34,1.58c-1.66.92-2.75,2.25-2.75,3.67s.67,2.66,2.25,2.66c1.75,0,2.75-1.08,2.75-2.08s-.41-2.42-.41-3a5.07,5.07,0,0,1,3.25-1.08,3.89,3.89,0,0,1,3.66,2.08,7.66,7.66,0,0,1,.67,2.75v2.25a26.22,26.22,0,0,1-4.25,1.67c-2.08.75-3.5,1.16-4.17,1.41A8.42,8.42,0,0,0,305,218.5a6.29,6.29,0,0,0-1.41,4.16,5.47,5.47,0,0,0,5.83,5.67,9.32,9.32,0,0,0,7.33-3.42h.09c.41,2.09,1.83,3.25,4.25,3.25a9.45,9.45,0,0,0,3.66-.91l-.08-1.34A6.68,6.68,0,0,1,322.09,225.66Zm-5.5-2.66a6.06,6.06,0,0,1-4.92,2.66c-2,0-3.5-1.16-3.5-3.66a5.2,5.2,0,0,1,2.5-4.34,24.6,24.6,0,0,1,6.17-2.5Z' />
        <path d='M352.34,225.91a6.61,6.61,0,0,1-5.75-2.83c-1.25-1.83-1.75-4.25-1.75-7.25h15v-1.5a11.05,11.05,0,0,0-2.09-6.83c-1.5-1.84-3.83-2.92-7.16-2.92a9.28,9.28,0,0,0-7.59,3.58,12.86,12.86,0,0,0-2.75,8.09,13.79,13.79,0,0,0,2.67,8.5,10.3,10.3,0,0,0,8.17,3.5c4.08,0,7.33-2.42,9.25-5.92l-1.67-.75C356.84,224.83,355,225.91,352.34,225.91Zm-1.84-19.75a4,4,0,0,1,3.84,2.34,13.48,13.48,0,0,1,.91,5.25h-10.5C345,209,347.34,206.16,350.5,206.16Z' />
        <path d='M384.42,225.33a2.62,2.62,0,0,1-1.17-2.33V194.75l-.33-.34-9.25.5v1.42c1.67.17,3.25.33,4.08.92a3.26,3.26,0,0,1,1.25,2.91v5.67a12.45,12.45,0,0,0-5.75-1.42,10.79,10.79,0,0,0-7.58,3.42,12.65,12.65,0,0,0-3.17,8.5,13.46,13.46,0,0,0,3,8.75,8.61,8.61,0,0,0,13.34.17l.16.08v2.08l.33.34,7.84-.5v-1.5A6.44,6.44,0,0,1,384.42,225.33Zm-5.58-1.92a6.43,6.43,0,0,1-5.09,2.5,6,6,0,0,1-5-2.83,11.6,11.6,0,0,1-1.58-6.42A14.32,14.32,0,0,1,369,209a5.51,5.51,0,0,1,4.59-2.75,5.83,5.83,0,0,1,3.66,1.33,5.69,5.69,0,0,1,1.59,2.5Z' />
        <path d='M390.75,207.75a12.27,12.27,0,0,0-3.08,8.66,11.7,11.7,0,0,0,3.25,8.75,10.44,10.44,0,0,0,7.5,3.17,10.32,10.32,0,0,0,8.33-3.58,13,13,0,0,0,2.92-8.67c0-3.58-1-6.42-3.09-8.5A11.19,11.19,0,0,0,390.75,207.75Zm8.08,18.91a5.26,5.26,0,0,1-4.91-3.16,15.44,15.44,0,0,1-1.5-7.42,15.06,15.06,0,0,1,1.33-6.67,5.25,5.25,0,0,1,5-3.25,5.35,5.35,0,0,1,4.83,3.17,17.71,17.71,0,0,1,1.42,6.83C405,222.75,402.67,226.66,398.83,226.66Z' />
        <path d='M434.08,225.75a1.94,1.94,0,0,1-1.25-2.09V211.91c0-4.5-2-7.41-6.33-7.41a8.42,8.42,0,0,0-4.67,1.33,26.19,26.19,0,0,0-2.91,2.5h-.09v-3.17l-.33-.33-7.5.5v1.5a5.72,5.72,0,0,1,2.33.58,3,3,0,0,1,1.25,2.5v13.92c0,1.17-.33,1.83-1.25,2.08a8.64,8.64,0,0,1-2.08.25v1.5h11.08v-1.5a4.73,4.73,0,0,1-2.16-.5,2,2,0,0,1-1.25-2.08v-13a6.16,6.16,0,0,1,1.83-2.08,6.94,6.94,0,0,1,4-1.34c2.17,0,3.92,1.84,3.92,5v11.67c0,1.17-.34,1.83-1.25,2.08a9,9,0,0,1-2.17.25v1.5h11.08l-.16-1.41A4.6,4.6,0,0,1,434.08,225.75Z' />
        <path d='M443.67,200.66a3.17,3.17,0,0,0,3-3.33,3,3,0,0,0-3-2.92,3.14,3.14,0,0,0,0,6.25Z' />
        <path d='M446.33,223.58V205.16l-.33-.33-8,.5v1.5a6.3,6.3,0,0,1,2.75.67,3.16,3.16,0,0,1,1.25,2.41v13.92c0,1.17-.33,1.83-1.25,2.08a8.76,8.76,0,0,1-2.08.25v1.5h10.91l.09-1.5a8.4,8.4,0,0,1-2.09-.5A1.87,1.87,0,0,1,446.33,223.58Z' />
        <path d='M160.17,267.58V249.16l-.33-.33-8,.5v1.5a6.37,6.37,0,0,1,2.75.67,3.16,3.16,0,0,1,1.25,2.41v13.92c0,1.17-.34,1.83-1.25,2.08a8.78,8.78,0,0,1-2.09.25v1.5h10.92l.08-1.5a8.32,8.32,0,0,1-2.08-.5A1.86,1.86,0,0,1,160.17,267.58Z' />
        <path d='M157.59,238.41a3.14,3.14,0,0,0,0,6.25,3.23,3.23,0,0,0,3-3.33A3,3,0,0,0,157.59,238.41Z' />
        <path d='M255.42,267.58V249.16l-.33-.33-8,.5v1.5a6.37,6.37,0,0,1,2.75.67,3.16,3.16,0,0,1,1.25,2.41v13.92c0,1.17-.34,1.83-1.25,2.08a8.78,8.78,0,0,1-2.09.25v1.5h10.92l.08-1.5a8.32,8.32,0,0,1-2.08-.5A1.86,1.86,0,0,1,255.42,267.58Z' />
        <path d='M249.92,241.58a3,3,0,0,0,2.83,3.08,3.11,3.11,0,0,0,3-3.33,3,3,0,0,0-3-2.92A3.05,3.05,0,0,0,249.92,241.58Z' />
        <path d='M470.5,225.66a2.36,2.36,0,0,1-1.25-2.33c0-4,.17-7,.17-11.33,0-3.09-.84-4.92-2.42-6.09s-3.42-1.41-6-1.41a10.71,10.71,0,0,0-5.33,1.58c-1.67.92-2.75,2.25-2.75,3.67s.66,2.66,2.25,2.66c1.75,0,2.75-1.08,2.75-2.08s-.42-2.42-.42-3a5.09,5.09,0,0,1,3.25-1.08,3.92,3.92,0,0,1,3.67,2.08,7.86,7.86,0,0,1,.66,2.75v2.25a26.22,26.22,0,0,1-4.25,1.67c-2.08.75-3.5,1.16-4.16,1.41a8.35,8.35,0,0,0-3.25,2.09,6.3,6.3,0,0,0-1.42,4.16,5.47,5.47,0,0,0,5.83,5.67,9.34,9.34,0,0,0,7.34-3.42h.16c.42,2.09,1.84,3.25,4.25,3.25a9.54,9.54,0,0,0,3.67-.91l-.17-1.34A6.68,6.68,0,0,1,470.5,225.66ZM465,223a6,6,0,0,1-4.92,2.66c-2,0-3.5-1.16-3.5-3.66a5.2,5.2,0,0,1,2.5-4.34,24.6,24.6,0,0,1,6.17-2.5Z' />
        <path d='M491.08,225.91a8.82,8.82,0,0,1-2.16.25v1.5H500l-.08-1.41a4.6,4.6,0,0,1-2.09-.5,1.94,1.94,0,0,1-1.25-2.09V211.91c0-4.5-2-7.41-6.33-7.41a8.42,8.42,0,0,0-4.67,1.33,21.86,21.86,0,0,0-3,2.5h-.08v-3.17l-.33-.33-7.5.5v1.5a5.72,5.72,0,0,1,2.33.58,3,3,0,0,1,1.25,2.5v13.92c0,1.17-.33,1.83-1.25,2.08a8.76,8.76,0,0,1-2.08.25v1.5H486v-1.5a4.8,4.8,0,0,1-2.17-.5,2,2,0,0,1-1.25-2.08v-13a6.3,6.3,0,0,1,1.84-2.08,6.91,6.91,0,0,1,4-1.34c2.16,0,3.91,1.84,3.91,5v11.67C492.33,225,492,225.66,491.08,225.91Z' />
        <path d='M125.92,240.83v-1.58H112.59v1.66a8.77,8.77,0,0,1,2.08.34,5.42,5.42,0,0,1,2.17.91c.92.59,1.42,2.84,1.42,6.25v13.34a8.76,8.76,0,0,1-1.75,5.33c-1.34,1.75-3.5,2.92-6.92,2.92-3.58,0-5.5-1.17-6.67-2.84a9.53,9.53,0,0,1-1.41-5.41v-18c0-1,.25-2,1.33-2.34a13.37,13.37,0,0,1,3.08-.58v-1.67H92.34v1.67a8.74,8.74,0,0,1,2.92.75,2.31,2.31,0,0,1,1.33,2.33v19.25a8.25,8.25,0,0,0,3.08,6.5,13.94,13.94,0,0,0,9.09,2.84,13.14,13.14,0,0,0,7.66-2.34,9.37,9.37,0,0,0,4.09-8V248c0-3.17.33-5.5,1.41-6.17a9.48,9.48,0,0,1,3.92-1Z' />
        <path d='M147.92,269.75a1.93,1.93,0,0,1-1.25-2.09V255.91c0-4.5-2-7.41-6.33-7.41a8.42,8.42,0,0,0-4.67,1.33,26.19,26.19,0,0,0-2.91,2.5h-.09v-3.17l-.33-.33-7.5.5v1.5a5.72,5.72,0,0,1,2.33.58,3,3,0,0,1,1.25,2.5v13.92c0,1.17-.33,1.83-1.25,2.08a8.64,8.64,0,0,1-2.08.25v1.5h11.08v-1.5a4.73,4.73,0,0,1-2.16-.5,2,2,0,0,1-1.25-2.08v-13a6.16,6.16,0,0,1,1.83-2.08,6.94,6.94,0,0,1,4-1.34c2.17,0,3.91,1.84,3.91,5v11.67c0,1.17-.33,1.83-1.25,2.08a8.82,8.82,0,0,1-2.16.25v1.5h11.08l-.17-1.41A4.56,4.56,0,0,1,147.92,269.75Z' />
        <path d='M187,249.08h-8.58v1.42a6.42,6.42,0,0,1,1.83.5c.75.25,1.34.75,1.34,1.25a7.31,7.31,0,0,1-.67,2.25c-1.92,4.83-3.67,8.75-4.75,11.41-.75-1.75-1.67-4-2.83-6.75s-2.09-4.91-2.67-6.5a6,6,0,0,1-.33-1c0-.33.33-.5,1-.66a5.25,5.25,0,0,1,1.66-.25v-1.59H162.59v1.5a4.5,4.5,0,0,1,3.5,2.42c2.58,6.17,4.91,11.67,7.75,18.83h1.83c.58-1.75,1.33-3.83,1.92-5.25,2.08-4.91,3.91-9.25,5.75-13.41a4.41,4.41,0,0,1,3.5-2.75H187Z' />
        <path d='M199,269.91a6.62,6.62,0,0,1-5.75-2.83c-1.25-1.83-1.75-4.25-1.75-7.25h15v-1.5a11,11,0,0,0-2.08-6.83c-1.5-1.84-3.83-2.92-7.17-2.92a9.53,9.53,0,0,0-7.58,3.67,12.83,12.83,0,0,0-2.75,8.08,13.72,13.72,0,0,0,2.67,8.5,10.26,10.26,0,0,0,8.16,3.5,10.43,10.43,0,0,0,9.25-5.92l-1.66-.83C203.5,268.83,201.67,269.91,199,269.91Zm-1.83-19.75A3.93,3.93,0,0,1,201,252.5a13.3,13.3,0,0,1,.92,5.25h-10.5C191.67,253,194,250.16,197.17,250.16Z' />
        <path d='M223.17,248.58c-1.92,0-4.5,1.33-6.33,3.75h-.09v-3.17l-.33-.33-7.5.5v1.5a5.72,5.72,0,0,1,2.33.58,3.21,3.21,0,0,1,1.25,2.5v14c0,1.17-.33,1.84-1.25,2.09a9.17,9.17,0,0,1-2.08.25v1.5h11.92v-1.5a9.67,9.67,0,0,1-3.09-.5,2,2,0,0,1-1.25-2.09V254.5a6.7,6.7,0,0,1,4.92-3.34,6.27,6.27,0,0,0-.25,1.75c0,1.09,1,2.09,2.67,2.09,1.5,0,2.33-1.42,2.33-2.92A3.17,3.17,0,0,0,223.17,248.58Z' />
        <path d='M237.75,258.08a19.74,19.74,0,0,1-4.33-1.75A3.11,3.11,0,0,1,232,253.5c0-1.75,1.5-3.25,4-3.25,3.09,0,5.42,2.25,6,5.5h1.34L243,248.5h-1.66l-.5,1.25a11,11,0,0,0-4.92-1.17,7.8,7.8,0,0,0-5.5,1.92,6.13,6.13,0,0,0,.25,9.41,14.12,14.12,0,0,0,4.67,2.17,13.31,13.31,0,0,1,4.33,1.83,3.48,3.48,0,0,1,1.5,2.84c0,2.5-1.67,3.83-4.58,3.83a6.68,6.68,0,0,1-4.92-2.42,10.52,10.52,0,0,1-2.25-4.25h-1.58l.25,7.84h1.66l.5-1.42a11,11,0,0,0,6,2,9.44,9.44,0,0,0,6.09-2,6.27,6.27,0,0,0,2.41-5.17,5.9,5.9,0,0,0-1.91-4.58A14.41,14.41,0,0,0,237.75,258.08Z' />
        <path d='M267.42,268a8.43,8.43,0,0,1-.58-4V251.41h6.41v-2.16h-6.5v-7.09h-4.16v7.09h-4.17v2.16h4.08v15.25c0,3.42,1.42,5.42,5.17,5.42a13.83,13.83,0,0,0,6.08-1.42V269a22.24,22.24,0,0,1-3.25.33C268.84,269.33,268,268.91,267.42,268Z' />
        <path d='M290.84,250.5a5.13,5.13,0,0,1,2,.58c.75.25,1.08.67,1.08,1.08a10.06,10.06,0,0,1-.83,2.5c-1.84,4.75-3.25,8-4.59,11.25-.66-1.75-1.58-4-2.75-6.83s-2.08-5-2.66-6.5a4.27,4.27,0,0,1-.34-1.08c0-.25.34-.42.92-.59a6,6,0,0,1,1.67-.25v-1.58H275v1.5a4.59,4.59,0,0,1,3.5,2.42c1.25,3,2.5,6,3.67,8.83s2.58,6.17,4,9.75a30.3,30.3,0,0,1-2.33,4.58c-1.09,1.75-1.92,2.84-2.75,3.25a10.29,10.29,0,0,0-1.34-1.91,2.67,2.67,0,0,0-2.16-1,2.32,2.32,0,0,0-2.17,2.58c0,1.5,1.42,2.83,3.83,2.83a4.88,4.88,0,0,0,3.67-1.66c1.42-1.34,2.83-3.59,4.75-7.84.75-1.66,1.75-4.16,3.17-7.66s3.08-7.34,4.91-11.59a5.39,5.39,0,0,1,3.5-2.66v-1.42h-8.41Z' />
      </g>
    </g>
  </svg>
)

export default Glasgow_Caledonian_University
