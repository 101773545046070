import React from 'react'
import { motion, useTransform, useViewportScroll } from 'framer-motion'
import styled, { useTheme } from 'styled-components/macro'

// Components
import AnimatedImage from '@components/AnimatedImage'
import Grid from '@components/ViewGridComponent'
import { HeroSubtitle, HeroTitle } from './Typography'
import SquaredLinkComponent from '@components/SquaredLinkComponent'

// Assets
import { heroLayer1, heroLayer2, heroLayer3 } from '../../../assets'

// Hooks
import useSetBackgroundColor from '@hooks/useSetBackgroundColor'
import useIsMobile from '@hooks/useIsMobile'
import SpacerComponent from '@components/SpacerComponent'

const variants = {
  visible: { opacity: 1 },
  hidden: {
    opacity: 0,
  },
}
const HeroView = () => {
  const { ref, inView } = useSetBackgroundColor()
  const theme = useTheme()
  const { scrollY } = useViewportScroll()
  const isMobile = useIsMobile()
  let height = 1000

  const isBrowser = typeof window !== 'undefined'

  if (isBrowser) height = window.innerHeight + window.innerHeight / 2

  const opacity = useTransform(scrollY, [height - 300, height - 100], [1, 0])

  const titleColumns = isMobile ? '1 / 13' : '3 / 11'
  const subTitleColumns = isMobile ? '2 / 12' : '4 / 10'

  return (
    <Hero>
      <Title ref={ref}>
        <TitleContent>
          <Grid
            placeContent='start'
            css={`
              padding: 0;
            `}
          >
            <Grid.Row
              css={`
                z-index: 3;
              `}
            >
              <Grid.Item column={titleColumns}>
                <HeroTitle />
              </Grid.Item>
              <Grid.Item column={subTitleColumns}>
                <HeroSubtitle />
              </Grid.Item>
            </Grid.Row>
          </Grid>
        </TitleContent>
      </Title>

      <BackgroundWrapper aria-hidden='true'>
        <img src={heroLayer1} alt='' />
        <AnimatedImage src={heroLayer2} offset={100} />
        <AnimatedImage src={heroLayer3} offset={-30} />
      </BackgroundWrapper>
    </Hero>
  )
}

const Hero = styled.section`
  background-color: ${({ theme }) => theme.colors.darkPurple.default};
  position: relative;
  overflow-y: clip;
`

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: max(25vh, 10rem);
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: min(20vh, 200px);
  }
`
const TitleContent = styled.div`
  min-width: 300px;
  max-width: 1300px;
  width: 90vw;
`

const BackgroundWrapper = styled.div`
  min-height: 80vh;
  overflow: clip;
  z-index: 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 60vh;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 50vh;
  }

  & img {
    position: absolute;

    width: 100%;
    height: auto;
    max-height: 100%;
    overflow: clip;
    min-width: 150vh;
    margin: auto;
  }
  /* buildings and moon */
  & img:nth-of-type(1) {
    bottom: 19vw;
    left: 50%;

    transform: translate(-50%);
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      bottom: 40vh;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      bottom: 80vw;
    }
  }
  /* houses and wind farm */
  & img:nth-of-type(2) {
    bottom: 7.5%;
  }
  /* Cool lemon on the bench */
  & img:nth-of-type(3) {
    bottom: -30px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      left: -40%;
    }
  }
`

export default HeroView
