import SectionComponent from '@components/SectionComponent'
import SubGrid from '@react-css/grid'
import { motion } from 'framer-motion'
import * as React from 'react'
import styled, { useTheme } from 'styled-components/macro'

// Components
import { FadeIn, StaggerAnimation } from '../../../components/Animations'
import {
  Heading,
  Highlight,
  Paragraph,
  VerticalHeading,
} from '../../../components/Typography'
import Grid from '../../../components/ViewGridComponent'

// Hooks

import Spacer from '@components/SpacerComponent'
import useIsMobile from '@hooks/useIsMobile'
import { Link } from 'gatsby'
import {
  Agile_Kinetic,
  Amber_Energy,
  Benthyg_Logo,
  CAFOD_Logo,
  Camden_Council,
  City_University_London,
  DTE_Logo,
  Glasgow_Caledonian_University,
  Hubbub_Logo,
  NHS_Logo,
  NIHR_Logo,
  Thrive_Logo,
  Town_Sq,
  UCL,
  Welsh_Gov,
} from './images'

const IconContainer = styled(motion.span)`
  & svg {
    height: 8rem;
    width: 100%;
  }
`

const Image = styled(motion.img)`
  width: 100%;
  height: auto;
  filter: brightness(0) invert(var(--color-certifications-invert));
`

const WhoWeAreHelping: React.FC = () => {
  const theme = useTheme()
  const isMobile = useIsMobile()

  return (
    <SectionComponent>
      <Grid.Item column={isMobile ? '1 / 13' : '4 / 10'}>
        <FadeIn>
          <Heading
            font='primary'
            css={`
              padding-bottom: ${isMobile
                ? theme.spacing.sm
                : theme.spacing.base};
              text-align: ${isMobile ? 'initial' : 'center'};
            `}
          >
            Who we <Highlight>help</Highlight>
          </Heading>
          <Paragraph
            size='md'
            css={`
              text-align: ${isMobile ? 'initial' : 'center'};
            `}
          >
            We focus on building products that have a positive outcome for the
            end-user, not the bottom line.
          </Paragraph>
        </FadeIn>
        <Spacer bottom='xl2' />
      </Grid.Item>
      {!isMobile && (
        <Grid.Item
          columnStart={1}
          columnEnd={2}
          alignSelfStretch
          justifySelfStart
        >
          <FadeIn>
            <VerticalHeading>Trusted By</VerticalHeading>
          </FadeIn>
        </Grid.Item>
      )}
      <Grid.Item column={isMobile ? '1 / 13' : '3 / 11'}>
        <SubGridIcons />
      </Grid.Item>
    </SectionComponent>
  )
}

const SubGridIcons = () => {
  const theme = useTheme()
  const isMobile = useIsMobile()

  const column_count = isMobile ? 2 : 3
  const row_gap = isMobile
    ? `min(10vw, ${theme.spacing.xl2})`
    : `min(3vw,  ${theme.spacing.xl2})`
  const column_gap = isMobile
    ? `min(10vw, ${theme.spacing.xl2})`
    : `min(6.25vw,  ${theme.spacing.xl2})`

  const icons = [
    {
      label: 'Agile Kinetic',
      Icon: Agile_Kinetic,
      url: '/case-study/health-tech-app/agile-kinetic',
    },
    {
      label: 'Amber Engergy',
      Icon: Amber_Energy,
      url: '/case-study/web-app/the-student-energy-project',
    },
    {
      label: 'Camden Council',
      Icon: Camden_Council,
    },
    {
      label: 'City University London',
      Icon: City_University_London,
    },
    { label: 'Town Square', Icon: Town_Sq },
    // {
    //   label: 'Fabric',
    //   Icon: Fabric,
    //   url: '/case-study/internal-web-app/fabric',
    // },
    // { label: 'NHS Wales', Icon: NHS_Wales },
    { label: 'NHS', Icon: NHS_Logo },
    { label: 'Thrive', Image: Thrive_Logo },
    { label: 'Benthyg', Image: Benthyg_Logo },
    { label: 'CAFOD', Image: CAFOD_Logo },
    { label: 'Hubbub', Image: Hubbub_Logo },
    { label: 'NIHR', Image: NIHR_Logo },
    { label: 'Down to Earth', Image: DTE_Logo },
    {
      label: 'Glasgow Caledonian University',
      Icon: Glasgow_Caledonian_University,
    },
    // {
    //   label: 'Swansea University',
    //   Icon: Swansea_University,
    // },
    {
      label: 'University College London',
      Icon: UCL,
      url: '/case-study/web-development/co-production-collective',
    },
    // { label: 'University of Wales Trinity Saint David', Icon: UWTSD },
    // { label: 'Mifuture', Icon: Mifuture },
    { label: 'Welsh Government', Icon: Welsh_Gov },
    // { label: 'SBRI', Icon: SBRI },
    // {
    //   label: 'Miller Research',
    //   Icon: Miller,
    // },
    // {
    //   label: 'Thomas Carroll Group',
    //   Icon: TCI,
    // },
  ]

  const itemVariant = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -10 },
  }
  return (
    <StaggerAnimation>
      <SubGrid
        columns={`repeat(${column_count}, minmax(0, 1fr))`}
        rows='repeat(4, minmax(0, 1fr))'
        gap={`${row_gap} ${column_gap}`}
        alignItems='center'
      >
        {icons.map(item => {
          const Icon = item.Icon
          const src = item.Image

          const icon = Icon ? (
            <IconContainer
              variants={itemVariant}
              whileHover={{ scale: 1.1 }}
              alt={item.label}
            >
              <Icon />
            </IconContainer>
          ) : (
            <Image
              variants={itemVariant}
              whileHover={{ scale: 1.1 }}
              src={src}
              alt={item.label}
            />
          )

          return (
            <Grid.Item>
              {item.url ? <Link to={item.url}>{icon}</Link> : icon}
            </Grid.Item>
          )
        })}
      </SubGrid>
    </StaggerAnimation>
  )
}

export default WhoWeAreHelping
