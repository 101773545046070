import React from 'react'

const NHS_Logo = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='var(--color-certifications-logo)'
    viewBox='0 0 500 202.37'
  >
    <g id='Layer_2' data-name='Layer 2'>
      <g id='layer'>
        <path d='M0,0V202.37H500V0ZM112.37,182.44,78.27,69.72h-.46L55.2,182.44H14.12L48.87,19.53h54.39l33.34,113h.51L160,19.53h41.08L166.5,182.44Zm165.89,0,14.47-69.77H241.38l-14.47,69.77H183.3L217.09,19.53h43.66L247.9,81.86h51.61l12.85-62.33H356L322.17,182.44Zm192.55-126a81.94,81.94,0,0,0-35.72-7.49c-17.25,0-31.26,2.58-31.26,15.64,0,23.12,63.24,14.47,63.24,63.94,0,45-41.79,56.72-79.58,56.72a182.29,182.29,0,0,1-50.6-8.4L347,143.68c8.65,5.62,25.91,9.36,40.12,9.36s34.76-2.58,34.76-19.38c0-26.15-63.24-16.34-63.24-62.32,0-42,36.88-54.64,72.6-54.64,20.24,0,38.95,2.12,49.93,7.23Z' />
      </g>
    </g>
  </svg>
)

export default NHS_Logo
