import React from 'react'

import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import styled from 'styled-components'

import HighFiveAnimation from '../../../animations/High5.json'

// Components
import HeadingSectionComponent from '@components/HeadingSectionComponent'
import Grid from '@components/ViewGridComponent'
import SectionComponent from '@components/SectionComponent'
import useIsMobile from '@hooks/useIsMobile'
import { useInView } from 'react-intersection-observer'

const PartnerWithChange: React.FC = () => {
  const isMobile = useIsMobile()
  const { ref, inView } = useInView({ threshold: 0.4 })

  return (
    <SectionComponent ref={ref}>
      <Grid.Row>
        <Grid.Item column={isMobile ? '2 / 12' : '5/9'}>
          <Circle isMobile={isMobile}>
            <HighFive shouldAnimate={inView} />
          </Circle>
        </Grid.Item>
      </Grid.Row>
      <HeadingSectionComponent
        heading='Partnering with people who want to bring about positive change.'
        content={[
          'You could be a founder with a big idea, a business looking for a transparent tech partner or a non-profit ready to showcase your community impact.',
          'Simply, it’s all about co-designing digital products with purpose, created with people that care, built by people that care.',
        ]}
        hasLink
        useAnchor
        linkLabel='See our work'
        linkTo='/our-work'
      />
    </SectionComponent>
  )
}

const HighFive: React.FC<{ shouldAnimate: boolean }> = props => {
  const lottieRef = React.useRef<LottieRefCurrentProps>(null)

  React.useEffect(() => {
    if (props.shouldAnimate && lottieRef.current) {
      lottieRef.current.play()
    }
  }, [props.shouldAnimate])

  return (
    <Lottie
      lottieRef={lottieRef}
      loop={false}
      animationData={HighFiveAnimation}
      autoplay={false}
    />
  )
}

const Circle = styled.div<{ isMobile: boolean }>`
  background-color: ${props => props.theme.colors.paleBlue.default};
  border-radius: 50%;
  padding: ${props => props.theme.spacing.xs};
  margin-bottom: ${props => props.theme.spacing.xl2};
`

export default PartnerWithChange
