import SeoComponent from '@components/SeoComponent'
import {
  Hero,
  PartnerWithChange,
  WeDoOurBit,
  WhoWeAreHelping,
} from '@views/HomeSections'
import React from 'react'
import { ThemeProvider } from 'styled-components'

import { blueTheme, darkPurpleTheme, heroThemeAlt, whiteTheme } from '../themes'

// Views
export default function Home() {
  return (
    <>
      <SeoComponent
        title='Big Lemon | Tech for Good App Development and Web Design'
        description='We’re Big Lemon, we build digital products that leave the world in a better place. Tech for good fuelled app development, web design and digital services.'
      />
      <ThemeProvider theme={heroThemeAlt}>
        <Hero />
      </ThemeProvider>
      <ThemeProvider theme={blueTheme}>
        <WeDoOurBit />
      </ThemeProvider>
      {/* <ThemeProvider theme={darkPurpleTheme}>
        <CoProduction />
      </ThemeProvider> */}
      <ThemeProvider theme={whiteTheme}>
        <PartnerWithChange />
      </ThemeProvider>
      <ThemeProvider theme={whiteTheme}>
        <WhoWeAreHelping />
      </ThemeProvider>
    </>
  )
}
