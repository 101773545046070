import * as React from 'react'

export const AgileKinetic: React.FC = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 500 84.3'
      fill='var(--color-certifications-logo)'
    >
      <defs>
        <style>{`.cls-1{fill:var(--color-certifications-logo);}.cls-1,.cls-3{fill-rule:evenodd;}.cls-2{mask:url(#mask);}.cls-4{mask:url(#mask-2-2);`}</style>
        <mask
          id='mask'
          x='444.39'
          y='0'
          width='27.23'
          height='27.23'
          maskUnits='userSpaceOnUse'
        >
          <g id='mask-2'>
            <polygon
              id='path-1'
              className='cls-1'
              points='444.39 0 471.62 0 471.62 27.23 444.39 27.23 444.39 0'
            />
          </g>
        </mask>
        <mask
          id='mask-2-2'
          x='414.77'
          y='18.43'
          width='85.23'
          height='55.19'
          maskUnits='userSpaceOnUse'
        >
          <g id='mask-4'>
            <polygon
              id='path-3'
              className='cls-1'
              points='414.77 18.43 500 18.43 500 73.62 414.77 73.62 414.77 18.43'
            />
          </g>
        </mask>
      </defs>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='layer'>
          <g id='Native-_Greyscales_' data-name='Native-[Greyscales]'>
            <g id='Group-8'>
              <g
                id='Far-far-away_-behind-the-word-mountains_-far-from-the-countries-Vokalia-and-Consonantia_-there-live-the-blind-texts-Copy-8'
                data-name='Far-far-away,-behind-the-word-mountains,-far-from-the-countries-Vokalia-and-Consonantia,-there-live-the-blind-texts-Copy-8'
              >
                <path
                  id='agile'
                  d='M17.5,65.41a15.19,15.19,0,0,0,12.31-6.06v5h6.24v-36H29.81v5.11a14.9,14.9,0,0,0-12.31-6C8.32,27.54,0,34,0,46.35,0,59,8.41,65.41,17.5,65.41Zm.7-5.54c-7.46,0-11.79-5.81-11.79-13.52C6.41,37.6,12,33.09,18.2,33.09c5.54,0,12,4.07,12,13.34C30.16,53.54,25.82,59.87,18.2,59.87ZM65.76,84.3c6.76,0,11.7-3,14.38-6.67C83.61,73,83.61,67.4,83.61,63.5V28.41H77.37v5.11a15,15,0,0,0-12.31-6,17.71,17.71,0,0,0-12.3,4.86c-2.6,2.6-5.2,6.93-5.2,14,0,7.28,2.77,11.78,5.2,14.21a17.23,17.23,0,0,0,12.3,4.85,15.19,15.19,0,0,0,12.31-6.06v5.19c0,3-.17,6.24-2,9.19-2.08,3.47-5.12,5-9.54,5a11.13,11.13,0,0,1-8.66-3.47,11.31,11.31,0,0,1-2.77-7.45H48.25a16.24,16.24,0,0,0,4.68,11.61A17.84,17.84,0,0,0,65.76,84.3Zm0-24.43A10.9,10.9,0,0,1,58,56.92C54.58,53.71,54,49.47,54,46.35a13.7,13.7,0,0,1,3.64-9.88,11.15,11.15,0,0,1,8.15-3.38A11.5,11.5,0,0,1,73.9,36.3a14.17,14.17,0,0,1,3.82,10.13,14.56,14.56,0,0,1-4.08,10.4A10.61,10.61,0,0,1,65.76,59.87Zm34.73-40.64a4.42,4.42,0,1,0-4.42-4.42A4.44,4.44,0,0,0,100.49,19.23Zm3.12,45.14v-36H97.37v36Zm20,0V1.2h-6.24V64.37Zm29.27,1a17.43,17.43,0,0,0,12-4.42,21.44,21.44,0,0,0,4.42-5.8l-5.2-2.86a14.36,14.36,0,0,1-3.73,4.94,10.91,10.91,0,0,1-7.36,2.6c-6.24,0-11.35-4.77-11.44-12.22h28c.17-4.77-1.13-10.31-3.38-13.52s-6.59-6.59-13.6-6.59c-10.84,0-17.42,8.32-17.42,19.07C135.13,60,144.23,65.41,152.89,65.41Zm10.23-23H142a10.94,10.94,0,0,1,10.58-9.36C157.83,33.09,162.08,36.82,163.12,42.45Z'
                />
                <path
                  id='kinetic'
                  d='M187.28,64.37V49.55L188.84,48l14.39,16.38h8.14l-18.2-20.54L208.6,28.41h-8.15L187.28,41.75V1.2H181V64.37Zm35.43-45.14a4.42,4.42,0,1,0-4.42-4.42A4.44,4.44,0,0,0,222.71,19.23Zm3.12,45.14v-36h-6.24v36Zm20,0V45.57c0-2.6.18-6.85,2.6-9.71a7.88,7.88,0,0,1,6.33-2.77,7.05,7.05,0,0,1,5.89,2.6c1.47,1.91,1.65,4.42,1.65,6.58v22.1h6.24V42.1c0-4-.26-6.93-2.08-9.62a12.22,12.22,0,0,0-10.31-4.94,12.72,12.72,0,0,0-10.32,5V28.41h-6.24v36Zm52,1a17.43,17.43,0,0,0,12-4.42,21.44,21.44,0,0,0,4.42-5.8L309,52.33a14.45,14.45,0,0,1-3.72,4.94,11,11,0,0,1-7.37,2.6c-6.24,0-11.35-4.77-11.44-12.22h28c.17-4.77-1.13-10.31-3.38-13.52s-6.58-6.59-13.6-6.59c-10.83,0-17.42,8.32-17.42,19.07C280.05,60,289.15,65.41,297.81,65.41Zm10.23-23H286.89a10.94,10.94,0,0,1,10.58-9.36C302.75,33.09,307,36.82,308,42.45Zm24.34,21.92V34.13h6.23V28.41h-6.23v-13h-6.24v13h-3.82v5.72h3.82V64.37Zm17.23-45.14a4.42,4.42,0,1,0-4.42-4.42A4.45,4.45,0,0,0,349.61,19.23Zm3.12,45.14v-36h-6.24v36Zm30.75,1a19.19,19.19,0,0,0,11.43-3.73V54.23a15.31,15.31,0,0,1-11.61,5.64c-6.41,0-12.65-4.6-12.65-13.35s6.07-13.43,12.74-13.43a14.48,14.48,0,0,1,11.26,5.37V31a19.05,19.05,0,0,0-11.17-3.47c-11.1,0-19.24,8.06-19.24,19.07C364.24,57.44,372.3,65.41,383.48,65.41Z'
                />
              </g>
              <g id='Group-7'>
                <g id='Group-3'>
                  <g className='cls-2'>
                    <path
                      id='Fill-1'
                      className='cls-3'
                      d='M471.62,13.62A13.62,13.62,0,1,1,458,0a13.61,13.61,0,0,1,13.62,13.62'
                    />
                  </g>
                </g>
                <g id='Group-6'>
                  <g className='cls-4'>
                    <path
                      id='Fill-4'
                      className='cls-3'
                      d='M499.82,27.81a11.45,11.45,0,0,0-22.45-.33s-1.62,8.69-6.37,13.73a39.68,39.68,0,0,1-10,7.61,9.23,9.23,0,0,1-7.27,0,39,39,0,0,1-10-7.61c-4.76-5-6.37-13.73-6.37-13.73a11.44,11.44,0,1,0-14.24,13.39l0,0h.18a11.27,11.27,0,0,0,2.18.35,30.34,30.34,0,0,1,13.27,6.6c6,5.1,7.3,9.41,7.44,11.93s-.36,5,1.16,7.75a11.3,11.3,0,0,0,20.05,0c1.51-2.79,1-5.15,1.16-7.75S470,53,476,47.86a30.29,30.29,0,0,1,13.27-6.6,11.14,11.14,0,0,0,2.18-.35h.18l0,0a11.42,11.42,0,0,0,8.21-13.06'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AgileKinetic
