import * as React from 'react'

const Town_Sq = () => (
  <svg
    fill='var(--color-certifications-logo)'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 500 163.18'
  >
    <g id='Layer_2' data-name='Layer 2'>
      <g id='layer'>
        <g id='Designed'>
          <g id='Group-118'>
            <polygon
              id='Path'
              points='193.38 147.53 193.38 99.76 172.32 99.76 172.32 86.04 231.29 86.04 231.29 99.76 209.87 99.76 209.87 147.53 193.38 147.53'
            />
            <path
              id='Shape'
              d='M240.91,148.74c-14.2,0-23.82-9.39-23.82-23s9.5-23,23.82-23,23.83,9.5,23.83,23S255,148.74,240.91,148.74Zm0-11.92c5.42,0,8-4.45,8-11.07s-2.41-11.07-8-11.07S233,119.13,233,125.75,235.26,136.82,240.91,136.82Z'
            />
            <polygon
              id='Path-2'
              data-name='Path'
              points='313.6 128.76 313.96 128.76 321.78 104.09 335.98 104.09 322.26 147.65 306.74 147.65 300.36 123.11 300 123.11 293.62 147.65 278.1 147.65 264.5 104.09 280.26 104.09 288.09 128.76 288.45 128.76 294.83 104.09 307.46 104.09 313.6 128.76'
            />
            <path
              id='Path-3'
              data-name='Path'
              d='M356,147.53H340.19V104.09H356v6.5c2.28-4.81,7.46-7.7,14.08-7.7,11.91,0,15.76,8.3,15.76,17.33v27.31H370v-23.1c0-5.9-1.69-9-6.5-9-5.42,0-7.46,4.34-7.46,10.83v21.3Z'
            />
            <path
              id='Path-4'
              data-name='Path'
              d='M406.74,125.27c.6,7.7,6.5,10.35,13.36,10.35,5.77,0,9.5-2.05,9.5-5.42,0-3.61-3.61-4.21-8.3-5l-11.19-1.92C400,121.42,393,115.76,393,104.93c0-12.51,9.75-20,25-20,17,0,26.11,8.3,26.47,21.9l-15.28.24c-.48-6.38-5.05-9.27-11.43-9.27-5.42,0-8.54,2.17-8.54,5.54,0,2.89,2.28,3.85,6.13,4.57l11.68,2c12.87,2.28,18.77,9,18.77,19,0,13.12-11.31,19.62-25.63,19.62-16.73,0-28.64-8.07-28.64-23Z'
            />
            <path
              id='Shape-2'
              data-name='Shape'
              d='M484.24,140.19c-2.05,5.06-7,8.55-14.2,8.55-12.88,0-19.38-10.11-19.38-23s6.5-23,19.38-23c7.22,0,12.15,3.49,14.2,8.66v-7.34H500v59.09H484.24Zm0-14.68c0-6.26-3.37-10.35-8.67-10.35-6.26,0-9,4.33-9,10.59s2.76,10.71,9,10.71c5.3,0,8.67-4.09,8.67-10.47Z'
            />
            <polygon
              id='Path-5'
              data-name='Path'
              points='24.67 24.55 61.61 24.55 61.61 0 0 0 0 61.49 24.67 61.49 24.67 24.55'
            />
            <polygon
              id='Path-6'
              data-name='Path'
              points='123.11 122.98 86.16 122.98 86.16 147.53 147.65 147.53 147.65 86.04 123.11 86.04 123.11 122.98'
            />
            <polygon
              id='Path-7'
              data-name='Path'
              points='24.67 86.04 0 86.04 0 147.53 61.61 147.53 61.61 122.98 24.67 122.98 24.67 86.04'
            />
            <polygon
              id='Path-8'
              data-name='Path'
              points='86.16 0 86.16 24.55 123.11 24.55 123.11 61.49 147.65 61.49 147.65 0 86.16 0'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Town_Sq
