import React from 'react'

const Welsh_Gov = () => (
  <svg
    fill='var(--color-certifications-logo)'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 500 124.35'
  >
    <g id='Layer_2' data-name='Layer 2'>
      <g id='layer'>
        <path d='M434.07,55.76c-.75,2.25-1.08,2.58-2.16,2.58a2.8,2.8,0,0,1-1.84-.33l-.33,2.92a4.81,4.81,0,0,0,2.17.33c2.58,0,3.66-1.5,4.75-4.75l7.25-24.42h-3.25l-4,16-4.34-16h-3.67l6.17,20.42Z' />
        <path d='M12.75,41.92l4.34,2.59c-5.84-13.42-12-5.84-12-5.84C10.92,36.84,11.67,40.84,12.75,41.92Z' />
        <path d='M21.84,28.5c5.08,0,8.75-4.75,12-4.75a22.79,22.79,0,0,1,5.83.75,8.75,8.75,0,0,0-5.83-2.91c-4.33,0-8.42,5.08-12,5.08a12,12,0,0,1-6.17-1.42A8.53,8.53,0,0,0,21.84,28.5Z' />
        <path d='M50.26,29.59a21.08,21.08,0,0,1,.33,7.25c-.33,8-15.67,18.92-15.67,32.09a19,19,0,0,0,5.84,12.42,31.2,31.2,0,0,1-1.42-13.84c1.42-9.5,13.5-20.75,13.09-29.84A13.09,13.09,0,0,0,50.26,29.59Z' />
        <path d='M65.51,26.92c-.33,6.17-9.5,13.5-7.25,24.42a20.83,20.83,0,0,0,7.25,10.92,56.61,56.61,0,0,1-3.25-11.67,15,15,0,0,1,1.83-9.08l.75.33,1,2.67a23.59,23.59,0,0,1-.33-7.25,30.08,30.08,0,0,0,1.83-5.5l.34.33,2.16,1.42a34.53,34.53,0,0,1-2.16-6.17c0-.34,0-.34-.34-.75A14.65,14.65,0,0,0,64.43,20,16.34,16.34,0,0,1,65.51,26.92Z' />
        <path d='M495.33,93.68V82h4.25V79.43h-4V74.35l-3.25,1.08v4h-3.25V82h3.25V94.77c0,3.66,1.5,4.75,4.75,4.75a7.41,7.41,0,0,0,2.59-.75V96.18a5.12,5.12,0,0,1-2.17.75C496.08,96.93,495.33,95.85,495.33,93.68Z' />
        <path d='M65.84,58s12-3.25,16-12.75c3.25-6.92-.75-12,4-22.59S127.77,1.58,127.77,1.58A205.42,205.42,0,0,0,104.1,6.67C96.43,9.25,84.76,13.92,79.68,20.5s-.75,16.76-2.17,23.67S65.84,58,65.84,58Z' />
        <path d='M20.42,51A115,115,0,0,1,32.09,62.26c.75-1.83,2.58-5.08,2.58-5.08A157.55,157.55,0,0,0,12.42,42.59C7.25,40.92.67,40.17,0,47.09a5.44,5.44,0,0,1,2.17-2.58A13.63,13.63,0,0,1,8,43.76,34.5,34.5,0,0,1,20.42,51Z' />
        <path d='M98.27,79.1a109.13,109.13,0,0,1-27.34,8.41H69.84c-5.83,0-7.25-1.41-7.25-1.41a13.61,13.61,0,0,1-4,1.41,34,34,0,0,1-2.25,3.26c.75.33,5.84,1.08,6.92,1.41C78.18,94,89.85,84.51,99.68,81.26Z' />
        <path d='M33.84,109.1c1.42-2.17,5.83-12.75,8-17.5A36.88,36.88,0,0,1,49.09,81c-7.58-8,2.59-17.5,2.59-17.5-5.84,1.42-8.76,4-9.84,7.25a17.7,17.7,0,0,0,.75,10.17,242.06,242.06,0,0,0-9.17,23.75,11.65,11.65,0,0,1-13.08,7.67c-5.42-.33-10.59,3.34-10.92,6.59a6.15,6.15,0,0,0,1.08,4.33c.34-4.75,7.67-5.83,13.84-6.17A19.79,19.79,0,0,0,33.84,109.1Z' />
        <path d='M46.51,99.18c.75-1.41,8-12.42,8.42-13.08A9.13,9.13,0,0,0,64.43,81c3.25-6.58-3.67-12.42-3.67-12.42s2.92,6.92-.33,10.17a11.48,11.48,0,0,1-9.09,2.92S42.92,107.18,28,117a36.54,36.54,0,0,1,11.67-.33c2.59,1.08,5.5,2.91,2.92,7.25,0,0,5.08-2.92,4-7.25C45.51,113,40,113,40,113s-1.42-.75-.75-2.92S45.84,100.6,46.51,99.18Z' />
        <path d='M9.75,60.51a4.9,4.9,0,0,0,6.17-1.42L17,56.93a4.53,4.53,0,0,0,.34-1.84A151.38,151.38,0,0,1,31.17,72.93a44.9,44.9,0,0,1-.75-6.59c-4.33-5.08-5.5-6.91-8.42-9.83C14.17,46.67,7.58,45.59,7.58,45.59,21.42,55.76,9.75,60.51,9.75,60.51Z' />
        <path d='M13.42,113a13.39,13.39,0,0,1,6.92-1.42,19.77,19.77,0,0,0,6.58-1.41,48.59,48.59,0,0,0-9.83-1.84C13.09,108.6,13.42,113,13.42,113Z' />
        <path d='M40.09,22a12.46,12.46,0,0,1,1.17,1.67,13.93,13.93,0,0,1-13.09,4,9.36,9.36,0,0,0,8.42,4c5.83,0,11.67-6.17,13.5-4,0,0-.75-8.42,9.84-11.67a12.53,12.53,0,0,1,2.58,2.92,11.25,11.25,0,0,0,3.25-9.08A12.11,12.11,0,0,0,60.68,0a13.55,13.55,0,0,1-1.09,7.67A7.79,7.79,0,0,1,53,12.42a15.7,15.7,0,0,1-4.75-.75c-1.67-.25-9.34-2.84-9.67,3.75,0,0-8-2.58-13.09,1.42l2.59,4.33A8.55,8.55,0,0,1,40.09,22Zm3.25-7.33c2.17.42,1.75.75,4,.33,0,0-2.58,4.34-6.17,1.84C41.17,16.84,41.17,14.25,43.34,14.67Z' />
        <polygon points='231.96 52.17 231.96 49.26 222.87 49.26 222.87 25.25 219.2 25.25 219.54 52.17 231.96 52.17' />
        <path d='M323.14,36.84V52.59h3.25V42c0-4.42,1.75-6.59,4.33-6.59.34,0,.75.34,1.09.34V32.51a5.33,5.33,0,0,0-5.51,3.25L326,32.51h-3.25c0,1.41.33,2.91.33,4.33Z' />
        <rect x='234.87' y='23.42' width='3.25' height='29.17' />
        <path d='M359.73,52.93a13.81,13.81,0,0,0,5.5-1.09l-.34-2.92a7.85,7.85,0,0,1-4.33,1.42c-3.67,0-5.5-2.17-5.5-6.92l11.25.5V42.09c0-5.83-2.58-9.5-6.92-9.5-5.08,0-7.66,4-7.66,10.17S354.64,52.93,359.73,52.93Zm-.67-17.84c2.58,0,4,2.17,4,6.17h-8C355.06,36.51,356.48,35.09,359.06,35.09Z' />
        <path d='M88.35,99.52a2.83,2.83,0,0,0-2.42,3.25,10.25,10.25,0,0,1,5-.75,22.07,22.07,0,0,0,4.75-1.09,16.48,16.48,0,0,0-7.25-1.41Z' />
        <path d='M384.31,53.34v-12c0-3.67,2.17-5.5,4.34-5.5s3.25,1.08,3.25,4.33V52.93h3.25V39.09c0-4.75-2.17-6.58-5.84-6.58a5,5,0,0,0-4.75,2.91v-12h-3.25s-.33,29.84-.25,29.92Z' />
        <path d='M101.27,34c0,.41,0,.75-.75,1.83A9.94,9.94,0,0,1,99.77,38s7.25-4.09,30.92,1.41A70,70,0,0,0,101.27,34Z' />
        <path d='M474.25,36.84V52.59h3.25V42c0-4.42,1.75-6.59,4.33-6.59.33,0,.75.34,1.08.34V32.51a5.32,5.32,0,0,0-5.5,3.25l-.33-3.25h-3.25c0,1.41.33,2.91.33,4.33Z' />
        <path d='M108.18,21.59C87.76,27.75,90.68,38,90.35,43.84S85.26,62.76,67,61.34a20.43,20.43,0,0,0,22.25-3.66c11.67-9.51,3.67-21.09,13.09-26.59s17.09-9.84,46.59-11.34A97.44,97.44,0,0,0,108.18,21.59Z' />
        <path d='M110.69,19l5.83-1.08s.33-8,11.67-15.67A28.77,28.77,0,0,0,110.69,19Z' />
        <path d='M69.84,64.51a67.4,67.4,0,0,1,23.34,7.34,22.58,22.58,0,0,1,19.34-14.59c-14.92-2.92-18.59,4.33-19.34,9.83A137.35,137.35,0,0,0,69.84,64.51Z' />
        <path d='M147.44,20.84s-25.08-.34-15.34,19.33c-30.15-1.46-20,16.34-19.58,16.76.33-16.42,19.67-16.76,19.67-16.76h-.09C132.08,40.1,127.82,24.83,147.44,20.84Z' />
        <path d='M96.52,52.18a19.54,19.54,0,0,1,13.83,3.66A22.78,22.78,0,0,0,97.6,49.26,12.37,12.37,0,0,0,96.52,52.18Z' />
        <path d='M328.8,84.51v2.92h4.76v8.42a13.46,13.46,0,0,1-3.67.75c-5.84,0-8.75-5.17-8.75-11.67s4.08-10.17,8.41-10.17a15,15,0,0,1,6.59,1.84V72.93a22.12,22.12,0,0,0-6.59-1.08c-6.91,0-12,5.41-12,13.83,0,7.25,3.67,13.84,12.42,13.84a17.66,17.66,0,0,0,6.92-1.42s0-13.5-.08-13.59Z' />
        <path d='M415.49,78.85a5.06,5.06,0,0,0-5.09,3.25l-.33-2.92h-3.25c0,1.42.33,2.92.33,4.33h.08V99.68h3.25v-12c0-3.67,2.17-5.5,4.34-5.5s3.25,1.08,3.25,4.33V99.27h3.25V85.43C421.32,80.68,419.15,78.85,415.49,78.85Z' />
        <path d='M318.64,48.92V23.5h-3.25v12a6.26,6.26,0,0,0-5.09-2.92c-4.33,0-6.92,3.25-6.92,10.17s2.92,10.17,6.92,10.17a5.47,5.47,0,0,0,5.09-3.25l.33,2.91H319C319,51.51,318.64,50,318.64,48.92ZM311,50.43c-2.92,0-4.34-2.59-4.34-7.67s1.42-7.67,4.34-7.67,4.42,2.5,4.33,7.67S313.89,50.43,311,50.43Z' />
        <path d='M344.89,50l.33,2.58h2.92a10.3,10.3,0,0,1-.33-3.25V39.17A6.35,6.35,0,0,0,346,34.42a5.85,5.85,0,0,0-5.08-1.83,9.74,9.74,0,0,0-5.5,1.42l.75,3.58a6.51,6.51,0,0,1,5.08-2.17c1.42,0,2.5,0,3.25,1.09a7.16,7.16,0,0,1,.75,3.25v1.08h-1.83c-6.92,0-9.5,2.58-9.5,6.58v1a5.09,5.09,0,0,0,5.5,4.51A6.3,6.3,0,0,0,344.89,50Zm-4.42.33c-2.16,0-3.25-1.5-3.25-3.67s1.5-4,5.5-4h2.5v3A4.72,4.72,0,0,1,340.47,50.34Z' />
        <path d='M297.88,79.1A5,5,0,0,0,293.13,82V70h-3.25s-.33,29.84-.25,29.92h3.25v-12c0-3.67,2.17-5.5,4.34-5.5s3.25,1.08,3.25,4.33V99.52h3.25V85.68C303.72,80.93,301.55,79.1,297.88,79.1Z' />
        <path d='M443.49,78.85a5.38,5.38,0,0,0-4.75,3.25,5,5,0,0,0-9.42,0L429,79.18h-3.25c0,1.42.33,2.92.33,4.33,0,0-.33,16.34-.25,16.42h3.25V87.51c0-3.66,1.5-5.08,3.67-5.08S436,83.51,436,86.1V99.6h3.25V87.18c0-3.67,1.5-5.08,3.67-5.08s3.25,1.08,3.25,3.66V99.27h3.16V85.43C449.32,80.68,447.16,78.85,443.49,78.85Z' />
        <path d='M461.41,79.18c-5.08,0-7.67,4-7.67,10.17s2.92,10.17,8,10.17a13.78,13.78,0,0,0,5.5-1.09l-.33-2.91a10.51,10.51,0,0,1-4.75,1.41c-3.67,0-5.5-2.16-5.5-6.92,0,0,11.67.42,11.67.51V88.68C468.33,82.85,465.74,79.18,461.41,79.18Zm-4.33,8.67c0-4.75,1.41-6.17,4-6.17s4,2.17,4,6.17Z' />
        <path d='M398.73,82.43l-.33-3.25h-3.25c0,1.42.33,2.92.33,4.33h.09V99.27h3.25V88.68c0-4.42,1.75-6.58,4.33-6.58.33,0,.75.33,1.08.33V79.18A5.32,5.32,0,0,0,398.73,82.43Z' />
        <path d='M426,52.18V48.59A7,7,0,0,1,421.24,50c-6.17,0-8.75-5.09-8.75-11.67s4.08-10.17,8.41-10.17A8.44,8.44,0,0,1,426,29.59V26.34a10.85,10.85,0,0,0-5.09-1.09c-7.25,0-12,5.42-12,13.84,0,7.25,4,13.84,12.42,13.84a11.49,11.49,0,0,0,4.75-.75Z' />
        <path d='M385.31,79.18c-5.08,0-7.66,4-7.66,10.17s2.91,10.17,8,10.17a13.81,13.81,0,0,0,5.5-1.09l-.33-2.91a10.55,10.55,0,0,1-4.76,1.41c-3.66,0-5.5-2.16-5.5-6.92,0,0,11.67.42,11.67.51V88.68C392.23,82.85,389.65,79.18,385.31,79.18Zm-4.75,8.67c0-4.75,1.42-6.17,4-6.17s4,2.17,4,6.17Z' />
        <path d='M300.8,42.67c0-6.16-2.92-10.16-8-10.16-5.5,0-8,4-8,10.16s2.5,10.17,8,10.17S300.8,48.84,300.8,42.67Zm-12.34-.33c0-5.08,1.42-7.67,4.34-7.67s4.33,2.59,4.33,7.67S296.05,50,292.8,50,288.46,47.42,288.46,42.34Z' />
        <path d='M349.64,79.1c-5.5,0-8,4-8,10.16s2.5,10.17,8,10.17,8-4,8-10.17S354.73,79.1,349.64,79.1Zm0,17.5c-3.33,0-4.75-2.5-4.75-7.67s1.5-7.67,4.75-7.67,4.75,2.59,4.75,7.67S352.89,96.6,349.64,96.6Z' />
        <path d='M378.65,52.18V49.26a2.59,2.59,0,0,1-1.84.75c-1.41,0-2.16-1.09-2.16-3.25V35.09h4V32.51h-4V27.42L371.4,28.5v4.34h-3.26v2.58h3.26V48.17c0,3.67,1.83,4.76,4.75,4.76a7.36,7.36,0,0,0,2.58-.75Z' />
        <path d='M101.18,99.85c1.84-4,1.09-9.08,2.59-13.09l.33-1.08c-1.08-.75-3.25-2.58-3.25-2.58-1.42,4-.33,10.25-1.42,13.5A7.46,7.46,0,0,1,91,102.77c-4.76-.34-8,2.58-8,4.75a3.86,3.86,0,0,0,.75,3.25C84.1,107.1,89.6,106,93.93,106A14.2,14.2,0,0,0,101.18,99.85Z' />
        <polygon points='367.06 95.52 362.73 79.1 359.06 79.1 364.89 98.77 368.89 98.77 375.06 79.1 371.39 79.1 367.06 95.52' />
        <path d='M496.42,49.59l.33,2.92H500c0-1.42-.33-2.92-.33-4.34l-.09-15.83h-3.25v12c0,3.67-2.16,5.5-4.33,5.5s-3.25-1.08-3.25-4.33V32.42H485.5V46.26c0,4.75,2.16,6.58,5.83,6.58A5.06,5.06,0,0,0,496.42,49.59Z' />
        <path d='M142.52,55c6.59-15.59,17.84-27.67,17.51-37.84a14.91,14.91,0,0,0-4.34-10.92l1.84-.33a70.17,70.17,0,0,0-9.84-4.75,38.61,38.61,0,0,1,5.5,9.08l.75-2.17a15,15,0,0,1,4,9.51c0,8.66-11.58,23.67-18.17,37.84h-4.33c-5.42.41-10.17.75-13.67.91a40.81,40.81,0,0,0-6.17,1.84,5.74,5.74,0,0,0-2.91,0c22.92,7.66,12.75,25.17,12.75,25.17A98.86,98.86,0,0,0,128,94.27c1.08,4.75,4.75,14.58,4.75,14.58a43.44,43.44,0,0,1,2.17,8.42,17.71,17.71,0,0,1,3.67.33h.33c2.17,0,5.83.75,4.75,4.75a3.89,3.89,0,0,0,1.08-5.08c-1.16-2.5-5.5-3.25-5.5-3.25a5.88,5.88,0,0,1-2.91-3.25l-2.17-5.5a56.81,56.81,0,0,1-4-19.67,27.79,27.79,0,0,0,1.42-13.5,15.48,15.48,0,0,0-7.67-9.84l4-1.08c2.92-.34,6.17-.75,9.83-1.09a40,40,0,0,0-3.25,17.09c0,8.75,3.59,13.09,7.25,14.92a17.52,17.52,0,0,0,9.51,2.58c7.58,0,16-5.08,16-18.92C167.28,65.93,157.11,56.84,142.52,55ZM162,84.93a12.15,12.15,0,0,1-9.84,6.92c-9.83,1.08-13.5-7.34-13.83-13.5a50,50,0,0,1,2.91-18.17A25.79,25.79,0,0,1,158,65.59a15.54,15.54,0,0,1,5.83,11.67A20.3,20.3,0,0,1,162,84.93Z' />
        <path d='M119.77,113.35a25.36,25.36,0,0,0,5.08,0c-1.41,0-4.33-3.25-8-2.58a3.17,3.17,0,0,0-3.33,3c0,.25.08.5.08.67A11.31,11.31,0,0,1,119.77,113.35Z' />
        <path d='M463.58,32.17a5.4,5.4,0,0,0-4.75,3.25,5,5,0,0,0-9.42,0l-.34-2.91h-3.25c0,1.41.34,2.91.34,4.33,0,0-.42,16.42-.34,16.42h3.25V40.84c0-3.67,1.51-5.08,3.67-5.08S456,36.84,456,39.42V52.93h3.25V40.51c0-3.67,1.5-5.09,3.67-5.09s3.25,1.09,3.25,3.67v13.5h3.25V38.76C469.41,34,467.24,32.17,463.58,32.17Z' />
        <path d='M120.85,86S111,86,98.6,75.85c4,10.58,19.34,13.5,19.34,13.5,0,.75,0,4.42,5.5,15.33.75,1.5,4.33,8.76,2.91,9.51a24.18,24.18,0,0,1-6.91.33c-5.09-.33-9.5,2.92-9.84,5.83a5.69,5.69,0,0,0,1.09,4c.33-4.41,4-4.75,9.08-5.5,5.83-.75,8.42-.41,12.42-.75,1.08-1.08-.75-4.75-.75-4.75A208.09,208.09,0,0,1,120.85,86Z' />
        <polygon points='238.54 94.02 233.79 72.18 229.46 72.18 224.7 94.02 220.37 72.18 216.7 72.18 222.2 99.52 226.2 99.52 231.29 76.18 236.37 99.52 240.37 99.52 246.54 72.18 243.29 72.18 238.54 94.02' />
        <path d='M111.1,106c-.75-2.59-4.75-2.92-4.75-2.92s-1.08-.33-.33-2.17,2.25-6.58,2.58-7.66A16.47,16.47,0,0,1,110,88.93a26.8,26.8,0,0,1-2.59-1.42c-1.08,2.17-3.33,14.92-9.41,18.84a25.51,25.51,0,0,1,8-.33c1.83.75,4,2.25,2.16,5.5C108.18,111.52,112.19,109.27,111.1,106Z' />
        <path d='M256.71,32.09h-3.25l-4,16-4.34-16h-3.66l6.16,20.42-.75,3.25c-.75,2.25-1.08,2.58-2.16,2.58a2.8,2.8,0,0,1-1.84-.33l-.33,2.92a4.81,4.81,0,0,0,2.17.33c2.58,0,3.66-1.5,4.75-4.75Z' />
        <path d='M282.21,88.26l-1.08-.33c-2.17-.67-3.25-1.83-3.25-3.25s1.08-2.58,3.25-2.58c1.42,0,2.92.33,4,.33l.33-2.58a12.77,12.77,0,0,0-4.33-.75h-.5a5.9,5.9,0,0,0-6.08,5.83c0,2.5,1.08,3.67,4,5.08l2.16,1.09c1.84,1.08,2.59,2.08,2.59,3.25s-1.42,2.58-4,2.58a7.52,7.52,0,0,1-4.75-1.41l-.42,2.91a12.53,12.53,0,0,0,5.5,1.09c4.75,0,6.58-2.5,6.58-6.17C286.21,90.85,284.8,89.35,282.21,88.26Z' />
        <rect x='266.96' y='70.01' width='3.25' height='29.17' />
        <polygon points='282.96 32.84 279.71 32.84 276.05 49.26 272.05 32.84 268.05 32.84 264.38 49.26 260.71 32.84 257.04 32.84 262.13 52.51 265.79 52.51 269.8 35.76 273.8 52.51 277.46 52.51 282.96 32.84' />
        <path d='M480,78.85a5.06,5.06,0,0,0-5.09,3.25l-.33-2.92h-3.25c0,1.42.33,2.92.33,4.33l.09,16.17H475v-12c0-3.67,2.16-5.5,4.33-5.5s3.25,1.08,3.25,4.33V99.27h3.25V85.43C485.83,80.68,483.66,78.85,480,78.85Z' />
        <path d='M256.38,79.18c-5.09,0-7.67,4-7.67,10.17s2.92,10.17,8,10.17a13.78,13.78,0,0,0,5.5-1.09l-.33-2.91a10.54,10.54,0,0,1-4.75,1.41c-3.67,0-5.5-2.16-5.5-6.92,0,0,11.66.42,11.66.51V88.68C263.29,82.85,260.71,79.18,256.38,79.18Zm-4.75,8.67c0-4.75,1.41-6.17,4-6.17s4,2.17,4,6.17Z' />
      </g>
    </g>
  </svg>
)

export default Welsh_Gov
