import { motion } from 'framer-motion'
import React from 'react'
import { useInView } from 'react-intersection-observer'

// @ts-expect-error
import { lemonListIcon } from '@assets/'

import { Paragraph } from '../Typography'
import styled from 'styled-components'

type AnimatedListProps = {
  items: string[]
  className?: string
}

const listVariants = {
  open: {
    transition: { staggerChildren: 0.2, delayChildren: 0 },
  },
  closed: {
    transition: { staggerChildren: 0.5, staggerDirection: -1 },
  },
}

const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 100, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 100 },
    },
  },
}

const Icon = styled.img`
  margin-right: ${props => props.theme.spacing.xs};
`

const ItemContainer = styled(motion.li)`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.xs};
  cursor: default;
  &:hover {
    cursor: default;
  }
`

const AnimatedList: React.FC<AnimatedListProps> = ({
  children,
  items,
  className,
}) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 1,
  })

  return (
    <motion.ul
      initial='closed'
      ref={ref}
      animate={inView ? 'open' : 'closed'}
      variants={listVariants}
      className={className}
    >
      {items.map((item, index) => {
        return (
          <ItemContainer
            key={`listItem_${index}`}
            variants={itemVariants}
            whileHover={{ scale: 1.05, x: 12 }}
          >
            <Icon src={lemonListIcon} alt='lemon icon' />
            <Paragraph size='sm'>{item}</Paragraph>
          </ItemContainer>
        )
      })}
      {children}
    </motion.ul>
  )
}

export default AnimatedList
